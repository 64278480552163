body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  /* .App {
    text-align: center;
  } */
  
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  form > div {
    margin-bottom: 10px;
  }
  
  button {
    margin: 5px;
  }
  
  .pac-container {
    z-index: 9999 !important; /* Ensure the autocomplete dropdown is on top of other elements */
  }